const store = {
  namespaced: true,

  state: {
    modal: [],
  },

  mutations: {
    OPEN: (state, name) => state.modal.unshift(name),
    CLOSE: (state, name) => (state.modal = state.modal.filter(e => e !== name)),
  },

  actions: {
    open: ({ commit }, name) => commit('OPEN', name),
    close: ({ commit }, name) => commit('CLOSE', name),
  },

  getters: {
    active: state => (state.modal.length > 0 ? state.modal[0] : null),
    allActive: state => state.modal,
  },
};
export default store;
