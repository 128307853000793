import { _handlerRespAxiosMonitorLogin } from "@design/styleguide";

const actions = {
  async SESSION_LOGOUT(_) {
    try {
      return await _handlerRespAxiosMonitorLogin("post", "session/logout");
    } catch (error) {
      console.log("[Error] in function: SESSION_LOGOUT", error);
      throw error;
    }
  },
  async SESSION_UPDATE(_) {
    try {
      return await _handlerRespAxiosMonitorLogin("post", "session/update");
    } catch (error) {
      console.log("[Error] in function: SESSION_UPDATE", error);
      throw error;
    }
  },
  async RENEW_TOKEN(_, { refreshToken }) {
    try {
      return await _handlerRespAxiosMonitorLogin(
        "post",
        "security/renew-token",
        { refreshToken }
      );
    } catch (error) {
      console.log("[Error] in function: RENEW_TOKEN", error);
      throw error;
    }
  },
}
export default actions;