<template>
  <isc-modal-alert
    v-if="isVisible"
    v-model="isVisible"
    :title="title"
    :paragraph="paragraph"
    :icon="icon"
    :iconColor="iconColor"
    :showBtn="showBtn"
    :showBtnClose="false"
    :showMainIcon="showMainIcon"
    @clicked="redirectToLogin"
  />
</template>

<script>
import { mapState, mapActions } from "vuex";
import { IscModalAlert, AlertSetting } from "@design/styleguide";
export default {
  name: "NoAuthModal",
  components: {
    IscModalAlert
  },
  mixins: [ AlertSetting ],
  data(){
    return {
      msgNoAuth: "The token is invalid or expired, we will redirect to the login.",
      titleNoAuth: "Token invalid",
    }
  },
  computed: {
    ...mapState("global/util", ["isVisible"]),
  },
  mounted(){
    this.errorModal(this.msgNoAuth);
  },
  methods: {
    ...mapActions("global/util", ["closeModal"]),
    redirectToLogin() {
      this.closeModal();
      sessionStorage.clear();
      window.location.href = `${process.env.VUE_APP_URL_MONITOR}/login`;
    },
  },
};
</script>

<style scoped >
</style>
