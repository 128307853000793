<template>
  <v-app id="main">
    <v-main>
      <router-view></router-view>
      <no-auth-modal/> 
    </v-main>
  </v-app>
</template>
<script>
import NoAuthModal from "./components/dialog/NoAuthModal.vue";
import { isTokenValid } from "@design/styleguide";
export default {
    components: {
      NoAuthModal
    },
    data() {
    return {
      isAuthenticated: false,
    };
  },
  created() {
    // return true if the token exists 
    this.isAuthenticated = isTokenValid();
    if (!this.isAuthenticated) {
      const pathUrl = `${process.env.VUE_APP_URL_MONITOR}/login`;
      window.location.replace(pathUrl);
    }
  }
}
</script>
<style>
/* #main {
  margin-top: 60px;
}
.v-application--wrap {
  min-height: calc(100vh - 60px);
} */
@media (max-width: 1024px) {
  body{
    font-size: 14px !important;
  }
}
</style>
