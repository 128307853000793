export const COMMON_VAR = [
  'id',
  'name',
  '_position',
  'templateId',
  'finalImage',
  'uuid',
  'dataObjects',
  'typeUser',
  'clientId',
  'folderId',
  'createdAt',
  'updatedAt',
]
export const ADDON = [
  'title',
  'description',
  'message',
  'logo',
  'styles',
  'addon_type',
  'folderId',
]
export const FOLDER = ['id', 'name', 'type']
