import moment from "moment";
export default {
  SET_LATEST_POSTS: (state, posts) => {
    state.latesPosts = posts
  },
  SET_FOLDERS_WITH_POSTS: (state, folders) => {
    state.foldersWithPosts = folders.data.map((el) => {
      return {
        id: el.id,
        name: el.name,
        type: el.type,
        items: el.items.map((p) => {
          return {
            id: p.id,
            uuid: p.uuid,
            typeUser: p.typeUser,
            finalImage: p.finalImage,
            name: p.name,
            imagetitle: "Card image",
            text: "Some quick example text to build on the card and make up the bulk of the card's content.",
            folderId: p.folderId,
            createdAt: moment(parseInt(p.createdAt)).format("DD-MM-YYYY"),
            dataObjects: p.dataObjects,
          };
        }),
      };
    });
  },
  SET_POST: (state, post) => {
    state.post = post
  },
  SET_POSTS_BY_USER: (state, posts) => {
    state.postsUser = posts;
  },
  SET_POSTS: (state, posts) => {
    state.posts = posts.map((p) => {
      return {
        id: p.id,
        uuid: p.uuid,
        typeUser: p.typeUser,
        finalImage: p.finalImage,
        name: p.name,
        imagetitle: "Card image",
        text: "Some quick example text to build on the card and make up the bulk of the card's content.",
        folderId: p.folderId,
        createdAt: moment(parseInt(p.createdAt)).format("DD-MM-YYYY"),
        dataObjects: p.dataObjects,
      };
    });
  },
}
