import {
  FIND_IMAGES_BY_CLIENT,
  FIND_IMAGES,
  FIND_IMAGES_WITH_KEYWORDS
} from '../graphql/query'
import { apollo } from '../../../plugins/vue-apollo'
import { messagePopUp } from "@design/styleguide";
// import { UPDATE_FOLDER } from "../../../post/folders/graphql/mutations";

export default {
  FIND_IMAGES_BY_CLIENT: (
    { commit, dispatch },
    { take, page, clientId },
  ) => {
    apollo
      .query({
        query: FIND_IMAGES_BY_CLIENT,
        variables: { take, page, clientId },
        fetchPolicy: 'no-cache',
      })
      .then(({ data: { items } }) => {
        console.log('data', items)
        commit('SET_IMAGES_BY_CLIENT', items)
      })
      .catch((err) => {
        console.log(err)
        dispatch('snackbar/errors', err, { root: true })
      })
  },
  /* IMAGES BY ADMIN */
  FIND_IMAGES: ({ commit, dispatch  }, { take, page }) => {
    apollo
      .query({
        query: FIND_IMAGES,
        variables: { take, page },
      })
      .then(({ data: { images } }) => {
        commit("SET_IMAGES", images);
      })
      .catch((err) => {
        console.error(err);
        dispatch('snackbar/errors', err, { root: true })
      });
  },
  /* IMAGES BY KEYWORDS ADMIN */
  FIND_WITH_KEYWORDS: ({ commit }, { keywords }) => {
    apollo
      .query({
        query: FIND_IMAGES_WITH_KEYWORDS,
        variables: { keywords },
      })
      .then(({ data: { imagesByKeywords } }) => {
        commit("SET_IMAGES_BY_KEYWORD", imagesByKeywords);
      })
      .catch((err) => {
        console.error(err);
        messagePopUp("Oops...", "Something went wrong!", "error", 1500);
      });
  },
}

