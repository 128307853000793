import gql from 'graphql-tag'
import { IMAGE, OBJECT } from './variables'

export const FIND_IMAGES_BY_CLIENT = gql(`
  query imagesByClient($take:Int!,$page:Int!, $clientId:Int!){
    items: imagesByClient(take:$take,page:$page, clientId:$clientId){
      ${IMAGE}
    }
  }
`)
export const FIND_IMAGES = gql(`
	query find($take: Int!, $page: Int!){
		images(take: $take, page: $page) {
			${IMAGE}
			header: object {
				${OBJECT}
			}
		}
	}
`);
export const FIND_IMAGES_WITH_KEYWORDS = gql(`
	query findByKeywords ($keywords: [String!]!){
		imagesByKeywords: findByKeywords(keywords: $keywords) {
			${IMAGE}
			header: object {
				${OBJECT}
			}
		}
	}
`);