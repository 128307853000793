
import { _apolloLink, _onError } from "@design/styleguide";

import Store  from "../store/index";

export const responseInterceptor = new _apolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    // Access headers from the context
    const context = operation.getContext();
    const responseHeaders = context.response?.headers;
    // Log or process the headers as needed
    if (responseHeaders) {
      // Example: Store a custom header globally
      const strNewToken = responseHeaders.get("X-New-Token");
      //console.log("Resp X-New-Token,", strNewToken);
      // this is for failing, "you are not authorized"
      // so we clean yours tokens
      if (!strNewToken) return response;
      if (strNewToken === "Token renewal failed")
        return response;
      //console.log("Pass token ");
      sessionStorage.setItem("token", strNewToken);
    }

    return response;
  });
});

// Error link to handle GraphQL and network errors
export const errorLink = _onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.error(`[GraphQL error]: Message: ${message}, Path: ${path}`);

        // Handle unauthorized errors
        if (message.includes("Unauthorized")) {
          Store.dispatch("global/util/triggerModal");
        }
      });
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`);

      // Handle network-specific issues
      if (networkError.message.includes("Failed to fetch")) {
        //Vue.prototype.$root.$emit("network-error-modal");
      }
    }

    // Optionally retry the request
    return forward(operation);
  }
);
