(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("vuex"), require("vue-router"), require("vue"), require("@design/styleguide"));
	else if(typeof define === 'function' && define.amd)
		define(["vuex", "vue-router", "vue", "@design/styleguide"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("vuex"), require("vue-router"), require("vue"), require("@design/styleguide")) : factory(root["vuex"], root["vue-router"], root["vue"], root["@design/styleguide"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__5880__, __WEBPACK_EXTERNAL_MODULE__6389__, __WEBPACK_EXTERNAL_MODULE__8bbf__, __WEBPACK_EXTERNAL_MODULE_ad3f__) {
return 