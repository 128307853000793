export default {
  SET_IMAGES_BY_CLIENT: (state, imagesByClient) => {
    state.imagesByClient = imagesByClient
  },
  SET_IMAGES: (state, images) => {
    state.images = images.map((image) => {
      return {
        id: image.id,
        urlImage: image.path,
        type: image.mimeType,
      };
    });
  },
  SET_IMAGES_BY_KEYWORD: (state, imagesByKeywords) => {
    state.imagesByKeywords = imagesByKeywords;
  },
}
