export default {
  latestUserAddons: [],
  foldersWithAddons: {
    count: 0,
    data: [],
  },
  addon: null,
  addonsByKeywords: [],
  addons: []
}
