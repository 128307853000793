import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import modals from './modals'
import posts from './posts'
import addon from './addon'
import image from './image'
import uploads from './uploads'
import global from "./global";
Vue.use(Vuex)

const idCliente = sessionStorage.getItem('client_id')
const monitorApi = process.env.VUE_APP_PUBLISH_API_QUERY

export default new Vuex.Store({
  state: {
    isLoading: false,
    accounts: [],
    isAccountsLoad: false,
    accountSelected: null,
    campaigns: [],
    campaignSelected: null,
    socialNetworks: [],
    showFacebook: true,
    showTwitter: true,
    showInstagram: true,
    showYoutube: true,
    showLinkedin: true,
    showAll: true,
    socialNetworksSelected: [],
    newPost: {
      text: null,
      hashtag: null,
      link: null,
      url_img: null,
      url_video: null,
      video_filename: null,
      title: null
    },
    loaderNetworks: false,
    submitPushed: false,
    submitPushedHashtagFaild: false,
    arrGaleria: [],
    arrGaleriaSelectedImage: [],
    arrGaleriaSelectedVideo: [],
    urlPostSelectedFB: null,
    typePost: {},
    contain: {
      yt: {},
      fb: {},
      twt: {}
    },
    urlPostSelectedTWT: null,
    urlPostSelectedYT: null,
    redSocial: null,
  },
  getters: {
    isLoading: (state) => state.isLoading,
    isAccountsLoad: (state) => state.isAccountsLoad,
    getSelectedAll: (state) => {
      return state.showAll
    },
    getAccountSelected: (state) => {
      return state.accountSelected
    },
    getCampaignSelected: (state) => {
      return state.campaignSelected
    },
    getListFacebook: (state) => {
      return state.socialNetworks.filter((network) => {
        return network.network === 'facebook'
      })
    },
    getListTwitter: (state) => {
      return state.socialNetworks.filter((network) => {
        return network.network === 'twitter'
      })
    },
    getListInstagram: (state) => {
      return state.socialNetworks.filter((network) => {
        return network.network === 'instagram'
      })
    },
    getListYoutube: (state) => {
      return state.socialNetworks.filter((network) => {
        return network.network === 'youtube'
      })
    },
    getListLinkedin: (state) => {
      return state.socialNetworks.filter((network) => {
        return network.network === 'linkedin'
      })
    },
    getTextNewPost: (state) => {
      return state.newPost.text
    },
    getTitleNewPost: (state) => {
      return state.newPost.title
    },
    getHashtagNewPost: (state) => {
      return state.newPost.hashtag
    },
    getLinkNewPost: (state) => {
      return state.newPost.link
    },
    getUrlImage: (state) => {
      return state.arrGaleriaSelectedImage[0]
    },
    getUrlVideo: (state) => {
      return state.arrGaleriaSelectedVideo[0]
    },
    getSocialNetworksSelected: (state) => {
      return state.socialNetworksSelected
    },
    getLoaderNetworks: (state) => {
      return state.loaderNetworks
    },
    getSubmitPushed: (state) => {
      return state.submitPushed
    },
    getSubmitPushedFaildHashtag: (state) => {
      return state.submitPushedHashtagFaild
    },
    getArrGaleria: (state) => {
      return state.arrGaleria
    },
    getUrlPostSelectedFB: (state) => {
      return state.urlPostSelectedFB
    },
    getTypePost: (state) => {
      return state.typePost
    },
    getContainPost: (state) => {
      return state.contain
    },
    getContainPostYT: (state) => {
      return state.contain.yt
    },
    getContainPostFB: (state) => {
      return state.contain.fb
    },
    getContainPostTwT: (state) => {
      return state.contain.twt
    },
    getUrlPostSelectedTWT: (state) => {
      return state.urlPostSelectedTWT
    },
    getUrlPostSelectedYT: (state) => {
      return state.urlPostSelectedYT
    },
    getRedSocial: (state) => state.redSocial,
    getNewPost: (state) => state.newPost,
  },
  mutations: {
    SHOW_LOADING: (state, value) => {
      state.isLoading = value
    },
    GET_ACCOUNTS(state, payload) {
      const accountList = payload.map((brand) => {
        const obj = {}
        obj.value = brand.idMarca
        obj.text = brand.dscMarca
        return obj
      })
      accountList.unshift({
        value: null,
        text: 'Please select an Account',
        disabled: true,
      })
      state.accounts = accountList
    },
    UPDATE_IS_ACCOUNT_LOAD(state, value){
      state.isAccountsLoad = value;
    },
    UPDATE_ACCOUNT_SELECTED(state, payload) {
      state.accountSelected = payload
    },
    UPDATE_SHOW_ALL_SELECTED(state, payload) {
      state.showAll = payload
    },
    UPDATE_CAMPAIGNS(state, payload) {
      const campaignList = payload.map((campaign) => {
        const obj = {}
        obj.value = campaign.idCampania
        obj.text = campaign.nomCampania
        return obj
      })
      campaignList.unshift({
        value: null,
        text: 'Please select an Campaign',
        disabled: true,
      })
      state.campaigns = campaignList
    },
    SET_GALERIA(state, arrGaleriaSet) {
      state.arrGaleria = arrGaleriaSet
    },
    SET_GALERIA_SELECTED_IMAGE(state, arrGaleriaSetSelected) {
      state.arrGaleriaSelectedImage = arrGaleriaSetSelected
    },
    SET_GALERIA_SELECTED_VIDEO(state, arrGaleriaSetSelected) {
      state.arrGaleriaSelectedVideo = arrGaleriaSetSelected
    },
    SET_CAMPAIGNS(state, payload) {
      state.campaigns = payload
    },
    UPDATE_CAMPAIGN_SELECTED(state, payload) {
      state.campaignSelected = payload
    },
    UPDATE_SOCIAL_NETWORKS(state, payload) {
      const socialNetworkList = payload.map((socialNetwork) => {
        const obj = {}
        obj.key = socialNetwork.idCuentaRedSocial
        obj.network = socialNetwork.M_RED_SOCIAL.nomRedSocial.toLowerCase()
        obj.idNetwork = socialNetwork.M_RED_SOCIAL.idRedSocial
        obj.name = socialNetwork.nomUsuarioRedsocial
        obj.url_profile = socialNetwork.dscLogo
        obj.nomCuentaRedSocial = socialNetwork.nomCuentaRedSocial
        return obj
      })
      state.socialNetworks = socialNetworkList
    },
    SET_SOCIAL_NETWORKS(state, payload) {
      state.socialNetworks = payload
    },
    SHOW_SECTION_NETWORK(state, payload) {
      if (payload === 'all') {
        state.showFacebook = true
        state.showTwitter = true
        state.showYoutube = true
        state.showInstagram = true
        state.showLinkedin = true
      } else if (payload === 'facebook') {
        state.showFacebook = true
        state.showTwitter = false
        state.showYoutube = false
        state.showInstagram = false
        state.showLinkedin = false
      } else if (payload === 'twitter') {
        state.showFacebook = false
        state.showTwitter = true
        state.showYoutube = false
        state.showInstagram = false
        state.showLinkedin = false
      } else if (payload === 'instagram') {
        state.showFacebook = false
        state.showTwitter = false
        state.showYoutube = false
        state.showInstagram = true
        state.showLinkedin = false
      } else if (payload === 'youtube') {
        state.showFacebook = false
        state.showTwitter = false
        state.showYoutube = true
        state.showInstagram = false
        state.showLinkedin = false
      } else if (payload === 'linkedin') {
        state.showFacebook = false
        state.showTwitter = false
        state.showYoutube = false
        state.showInstagram = false
        state.showLinkedin = true
      }
    },
    UPDATE_TEXT_NEW_POST(state, payload) {
      state.newPost.text = payload
    },
    UPDATE_TITLE_NEW_POST(state, payload) {
      state.newPost.title = payload
    },
    UPDATE_HASHTAG_NEW_POST(state, payload) {
      state.newPost.hashtag = payload
    },
    UPDATE_LINK_NEW_POST(state, payload) {
      state.newPost.link = payload
    },
    UPDATE_URL_IMAGE(state, payload) {
      state.newPost.url_img = payload
      state.newPost.url_video = null
    },
    UPDATE_URL_VIDEO(state, payload) {
      //console.log('ACTUALIZA', payload)
      state.newPost.url_video = payload.url
      state.newPost.video_filename = payload.filename
      state.newPost.url_img = null
    },
    SELECT_SOCIAL_NETWORK(state, payload) {
      state.socialNetworksSelected.push(payload)
    },
    UNSELECT_SOCIAL_NETWORK(state, payload) {
      state.socialNetworksSelected = state.socialNetworksSelected.filter(
        (netwokSelected) => {
          return netwokSelected.key !== payload.key
        },
      )
    },
    CLEAN_LIST_SELECTED(state) {
      state.socialNetworksSelected = []
    },
    START_LOADER_NETWORKS(state) {
      state.loaderNetworks = true
    },
    OFF_LOADER_NETWORKS(state) {
      state.loaderNetworks = false
    },
    PUSH_SUBMIT_BUTTON(state) {
      state.submitPushed = true
    },
    PUSH_SUBMIT_BUTTON_FAILD_HASHTAG(state, payload) {
      //console.log('value hastag', payload)
      state.submitPushedHashtagFaild = payload
    },
    UPDATE_URL_POST_SELECTED_FB(state, text) {
      try {
        //console.log('el UPDATE_URL_POST_SELECTED_FB: ', text)
        state.urlPostSelectedFB = text
      } catch (error) {
        console.error('el error UPDATE_URL_POST_SELECTED_FB: ', error)
      }
    },
    UPDATE_TYPE_POST(state, { red, type }) {
      state.typePost[red] = type
    },
    UPDATE_CONTAIN_POST(state, contain) {
      state.contain = contain
    },
    UPDATE_CONTAIN_POST_RED(state, { red, object }) {
      state.contain = { ...state.contain, [red]: { ...object } }
    },
    SET_UUID_ADDON(state, { networkId , contain} ){
      let idsSocialNetworks = [ 1, 5]; 
      let data = {
        uuid: contain.uuid,
        title: contain?.name,
        description: contain?.description
      }
      if( idsSocialNetworks.includes(networkId)){ 
        state.contain.fb = { ...data };
      } else {
        state.contain.twt = { ...data };
      }

    }, 
    UPDATE_URL_POST_SELECTED_TWT(state, text) {
      try {
        //console.log('el UPDATE_URL_POST_SELECTED_FB: ', text)
        state.urlPostSelectedTWT = text
      } catch (error) {
        console.error('el error UPDATE_URL_POST_SELECTED_FB: ', error)
      }
    },
    UPDATE_URL_POST_SELECTED_YT(state, text) {
      try {
        //console.log('el UPDATE_URL_POST_SELECTED_YT: ', text)
        state.urlPostSelectedYT = text
      } catch (error) {
        console.error('el error UPDATE_URL_POST_SELECTED_YT: ', error)
      }
    },
    SET_RED_SOCIAL: (state, text) => {
      state.redSocial = text
    },
  },
  actions: {
    asyncUser(context) {
      context.commit('UPDATE_IS_ACCOUNT_LOAD', true);
      //axios.get(ruta + '/cliente/'+idCliente)
      axios
        .get(`${monitorApi}/Publish/cliente/${idCliente}`)
        .then((response) => {
          context.commit('GET_ACCOUNTS', response.data);
          context.commit('UPDATE_IS_ACCOUNT_LOAD', false);
          // console.log('Usuario traido exitosamente', response.data)
        })
        .catch((error) => {
          console.log(`Fallo traer al usuario error: ${error}`)
        })
    },
    asyncCampaigns(context, payload) {
      //axios.get(ruta + '/campania/'+payload+'?idCliente='+idCliente)
      axios
        .get(`${monitorApi}/Publish/campania/${payload}?idCliente=${idCliente}`)
        .then((response) => {
          context.commit('UPDATE_CAMPAIGNS', response.data)
          //console.log('Campanas traidas exitosamente', response.data)
        })
        .catch((error) => {
          console.log(`Fallo traer las campanas error: ${error}`)
        })
    },
    asyncNetworks(context, payload) {
      //axios.get(ruta + '/account/'+payload)
      axios
        .get(`${monitorApi}/Publish/account/${payload}`)
        .then((response) => {
          context.commit('UPDATE_SOCIAL_NETWORKS', response.data)
          context.commit('OFF_LOADER_NETWORKS')
          //console.log('Social Networks traidas exitosamente', response.data)
        })
        .catch((error) => {
          console.log(`Fallo traer las campanas error: ${error}`)
        })
    },
  },
  modules: {
    modals,
    posts,
    addon,
    image,
    uploads,
    global
  },
})
