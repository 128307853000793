const actions = {
  // These actions are for the modal NoAuth
  TRIGGER_MODAL_NO_AUTH({ commit }) {
    commit("SHOW_MODAL_NO_AUTH");
  },
  CLOSE_MODAL_NO_AUTH({ commit }) {
    commit("HIDE_MODAL_NO_AUTH");
  },
  MSG_NO_AUTH({ commit }, msgNoAuth) {
    commit("SET_MSG_NO_AUTH", msgNoAuth);
  },
};

export default actions;
