import moment from "moment";
export default {
  SET_LATEST_USER_ADDONS: (state, latestAddons) => {
    state.latestUserAddons = latestAddons
  },
  SET_FOLDERS_WITH_ADDONS: (state, folders) => {
    state.foldersWithAddons = folders.data.map((el) => {
      return {
        id: el.id,
        name: el.name,
        type: el.type,
        items: el.items.map((p) => {
          return {
            id: p.id,
            uuid: p.uuid,
            typeUser: p.typeUser,
            finalImage: p.dataObjects.image.path,
            // finalImage: p.dataObjects.image.thumbnails[2].location,
            // finalImage: p.finalImage,
            name: p.name,
            imagetitle: p.title,
            text: p.description,
            folderId: p.folderId,
            createdAt: moment(parseInt(p.createdAt)).format("DD-MM-YYYY"),
            dataObjects: p.dataObjects,
          };
        }),
      };
    });
  },
  SET_ADDON: (state, addon) => {
    state.addon = addon
  },
  FIND_ADDONS_BY_KEYWORDS: (state, addons) => {
    state.addonsByKeywords = addons;
  },
  SET_ADDONS: (state, addons) => {
    state.addons = addons.map((addon) => {
      return {
        id: addon.id,
        uuid: addon.uuid,
        typeUser: addon.typeUser,
        finalImage: addon.dataObjects.image.path,
        // finalImage: addon.dataObjects.image.thumbnails[2].location,
        // finalImage: addon.finalImage,
        name: addon.name,
        imagetitle: addon.title,
        text: addon.description,
        folderId: addon.folderId,
        createdAt: moment(parseInt(addon.createdAt)).format("DD-MM-YYYY"),
        dataObjects: addon.dataObjects,
      };
    });
  },
}
