export default {
  latesPosts: [],
  foldersWithPosts: {
    count: 0,
    data: [],
  },
  post: null,
  postsUser: [],
  posts: [],
}
