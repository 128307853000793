import util from "./util/index"
import auth from "./auth/index"

export default {
  modules: {
    util,
    auth
  },
  namespaced: true,
};
