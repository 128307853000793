const actions = {
  triggerModal({ commit }) {
    commit("SHOW_MODAL");
  },
  closeModal({ commit }) {
    commit("HIDE_MODAL");
  },
};

export default actions;
