import VueRouter from 'vue-router'

const routes = [
  {
    path: '/publish',
    name: 'Publish',
    component: () => import('./view/Publish.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
