import gql from 'graphql-tag'
import { POST, FOLDER } from './variables'

export const FIND_LATEST_POSTS = gql(`
  query latestPosts($take:Int!,$typeUser:String, $clientId:Int!){
    items: latestPosts(take:$take,typeUser:$typeUser, clientId:$clientId){
      ${POST}
    }
  }
`)

export const FIND_FOLDERS_WITH_POSTS = gql(`
  query listFoldersWithPosts($limitPost:Int!,$page:Int!,$take:Int!, $clientId:Int!){
    folders:listFoldersWithPosts(page:$page, take:$take, clientId:$clientId){
      count
      data{
        ${FOLDER}
        items:posts(limitPost: $limitPost){
          ${POST}
        }
      }

    }
  }
`)

export const FIND_POST = gql(`
	query find($uuid: String!){
		post: getPostByUUID(uuid: $uuid) {
			${POST}
		}
	}
`)

// typeUser: User, created Posts
export const FIND_POSTS_BY_TYPE = gql(`
	query findPostsByType ($page: Int!, $take: Int!, $names: [String!]!, $user: String!, $clientId:Int!){
		posts: templatesByName( page: $page, take: $take, names: $names, user: $user, clientId: $clientId) {
			${POST}
		}
	}
`);
export const FIND_POSTS_BY_FOLDER_ID = gql(`
  query postsByFolderPaginate($page: Int!, $take: Int!, $folderId: Int!, $clientId:Int!) {
    posts: postsByFolderPaginate(page:$page, take: $take, folderId: $folderId, clientId:$clientId) {
			${POST}
    }
  }
`);
