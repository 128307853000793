import gql from 'graphql-tag'
import { ADDON, COMMON_VAR, FOLDER } from './variables'

export const FIND_LATEST_ADDONS = gql(`
  query latestAddons($take: Int!, $typeUser: String, $clientId: Int!) {
    latestAddons: latestAddons(take: $take, typeUser: $typeUser, clientId: $clientId) {
			${ADDON}
			${COMMON_VAR}
    }
  }
`)
export const FIND_FOLDERS_WITH_ADDONS = gql(`
  query listFoldersWithAddons($limitAddon:Int!,$page:Int!,$take:Int!, $clientId:Int!){
    folders:listFoldersWithAddons(page:$page, take:$take, clientId: $clientId ){
      count
      data{
        ${FOLDER}
        items:addons(limitAddon: $limitAddon){
          ${ADDON}
          ${COMMON_VAR}
        }
      }

    }
  }
`)

export const FIND_ADDON = gql(`
	query find($uuid: String!){
		addon: getAddonByUuid(uuid: $uuid) {
			${ADDON}
			${COMMON_VAR}
		}
	}
`)
/* Find addons by keywords, typeUser and Client Id */
export const FIND_BY_KEYWORDS = gql(`
	query addonsByKeywords ($take:Int, $page:Int, $keywords: [String!]!, $typeUser: String, $clientId: Int){
		addons: addonsByKeywords(take: $take, page: $page, keywords: $keywords, typeUser: $typeUser, clientId: $clientId) {
			${ADDON}
			${COMMON_VAR}
		}
	}
`);
export const FIND_ADDONS_BY_FOLDER_ID = gql(`
  query AddonsByFolderPaginate($page: Int!, $take: Int!, $folderId: Int!, $clientId:Int!) {
    addons: listAddonsByFolderPaginate(page:$page, take: $take, folderId: $folderId, clientId:$clientId) {
			${ADDON}
      ${COMMON_VAR}
    }
  }
`);
