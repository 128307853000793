import gql from 'graphql-tag'
import { UPLOAD } from './variables'

export const CREATE_UPLOAD = gql(`
  mutation createUpload($upload: UploadsDTO!, $object: ObjectsDTO!){
    upload: createUpload(upload: $upload, object: $object) {
      ${UPLOAD}
    }
  }
`)
export const DELETE_UPLOAD = gql(`
  mutation removeUpload($id: Int!){
    upload: removeUpload(id: $id) 
  }
`);