import './set-public-path'
import 'vue-loading-overlay/dist/vue-loading.css'
import '../public/css/basic.css'
import Vue from 'vue';
import App from './App.vue'
import store from './store'
import router from './router'
import singleSpaVue from 'single-spa-vue';
import VueLoading from 'vue-loading-overlay'
import { vuesweetalert2, Vuelidate, vuetify } from '@design/styleguide'
import { createProvider } from './plugins/vue-apollo'

Vue.use(VueLoading)
Vue.use(vuesweetalert2)
Vue.use(Vuelidate)
const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render: (h) => h(App),
    el: "#publish",
    router,
    store,
    vuetify,
    apolloProvider: createProvider(),
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;

