import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client'
import { AuthLink, _apolloLink } from "@design/styleguide";
import { errorLink } from "../utils/apollo.helper";

// Install the vue plugins
// Vue.use(VueApollo)
if (!Vue.prototype.hasOwnProperty('$apollo')) {
  Vue.use(VueApollo);
}

// Http endpoint
let httpEndpoint

if (process.env.NODE_ENV === 'production') {
  httpEndpoint = process.env.VUE_APP_API_GRAPHQL_DESIGN
} else {
  httpEndpoint = process.env.VUE_APP_API_LOCAL_GRAPHQL_DESIGN
}

// Config
const defaultOptions = {
  httpEndpoint,
  persisting: false,
  websocketsOnly: false,
  ssr: false,
  link: _apolloLink.from([AuthLink, errorLink])
}

// Call this in the Vue app file
export function createProvider(options = {}) {
  // Create apollo client
  const { apolloClient } = createApolloClient({
    ...defaultOptions,
    ...options,
  })

  // Create vue apollo provider
  return new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'cache-and-network',
      },
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log(
        '%cError',
        'backgrounds: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
        error.message,
      )
    },
  })
}

export const apollo = createProvider().defaultClient
