export const IMAGE = ['id', 'path', 'mimeType', 'width', 'height', 'thumbnails']
export const OBJECT = [
  "id",
  "uuid",
  "clientId",
  "typeUser",
  "keywords",
  "createdAt",
  "updatedAt",
];
