import {
  _apolloLink,
  _onError,
  _MSG_ERRORS_INVALID_TOKEN,
  _MSG_ERRORS_NO_AUTH_SESSION,
} from "@design/styleguide";

import Store from "../store/index";

// Error link to handle GraphQL and network errors
export const errorLink = _onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.error(`[GraphQL error]: Message: ${message}, Path: ${path}`);

        //* ===== HANDLE ERROR =====
        if (_MSG_ERRORS_INVALID_TOKEN.includes(message)) {
          //* ===== HANDLE INVALID TOKEN =====
          const msg = {
            title: "Invalid Token",
            message: `${message}, it will redirect to login.`,
          };
          Store.dispatch("global/util/MSG_NO_AUTH", msg);
          Store.dispatch("global/util/TRIGGER_MODAL_NO_AUTH");
        } else if (_MSG_ERRORS_NO_AUTH_SESSION.includes(message)) {
          console.log("=== NO AUTH SESSION ===");
          const msg = {
            title: "Invalid Session",
            message: `${message}, it will redirect to login.`,
          };
          Store.dispatch("global/util/MSG_NO_AUTH", msg);
          Store.dispatch("global/util/TRIGGER_MODAL_NO_AUTH");
        } else if (message.includes("Unauthorized")) {
          //* ===== HANDLE UNAUTHORIZED =====
          Store.dispatch("global/util/TRIGGER_MODAL_NO_AUTH");
        }
      });
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`);

      // Handle network-specific issues
      if (networkError.message.includes("Failed to fetch")) {
        //Vue.prototype.$root.$emit("network-error-modal");
      }
    }

    // Optionally retry the request
    return forward(operation);
  }
);
//! DO NOT ERASE IT, IT COULD BE USEFUL IN THE FUTURE
/* export const responseInterceptor = new _apolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    // Access headers from the context
    const context = operation.getContext();
    const responseHeaders = context.response?.headers;
    // Log or process the headers as needed
    if (responseHeaders) {
      // Example: Store a custom header globally
      const strNewToken = responseHeaders.get("X-New-Token");
      //console.log("Resp X-New-Token,", strNewToken);
      // this is for failing, "you are not authorized"
      // so we clean yours tokens
      if (!strNewToken) return response;
      if (strNewToken === "Token renewal failed")
        return response;
      //console.log("Pass token ");
      sessionStorage.setItem("token", strNewToken);
    }

    return response;
  });
}); */
