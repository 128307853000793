import { apollo } from '../../../plugins/vue-apollo'

import {
  FIND_LATEST_ADDONS,
  FIND_FOLDERS_WITH_ADDONS,
  FIND_ADDON,
  FIND_BY_KEYWORDS,
  FIND_ADDONS_BY_FOLDER_ID
} from '../graphql/query'
import { messagePopUp } from "@design/styleguide";
export default {
  //** LIST LATEST ADDONS
  FIND_LATEST_ADDONS_BY_TYPE: (
    { commit, dispatch },
    { take, typeUser, clientId },
  ) => {
    apollo
      .query({
        query: FIND_LATEST_ADDONS,
        variables: { take, typeUser, clientId },
        fetchPolicy: 'no-cache',
      })
      .then(({ data: { latestAddons } }) => {
        switch (typeUser) {
          case 'admin':
            commit('SET_LATEST_ADMIN_ADDONS', latestAddons)
            break
          case 'user':
            commit('SET_LATEST_USER_ADDONS', latestAddons)
            break
        }
      })
      .catch((err) => {
        dispatch('snackbar/errors', err, { root: true })
      })
  },

  //* FOLDER
  LIST_ADDONS_WITH_FOLDER: (
    { commit },
    { take, page, limitAddon, clientId },
  ) => {
    apollo
      .query({
        query: FIND_FOLDERS_WITH_ADDONS,
        variables: { take, page, limitAddon, clientId },
        fetchPolicy: 'no-cache',
      })
      .then(({ data: { folders } }) => {
        commit('SET_FOLDERS_WITH_ADDONS', folders)
      })
      .catch((err) => {
        console.log(err)
        // dispatch('snackbar/errors', err, { root: true })
      })
  },
  FIND_ADDON: ({ commit }, uuid) => {
    apollo
      .query({
        query: FIND_ADDON,
        variables: { uuid },
        fetchPolicy: 'no-cache',
      })
      .then(({ data: { addon } }) => {
        commit('SET_ADDON', addon)
      })
      .catch((err) => {
        console.log(err)
      })
  },
  FIND_ADDONS_BY_KEYWORDS: ({ commit, dispatch }, { take , page, keywords, typeUser, clientId }) => {
    apollo
      .query({
        query: FIND_BY_KEYWORDS,
        variables: { take, page, keywords, typeUser, clientId },
      })
      .then(({ data: { addons } }) => {
        commit("FIND_ADDONS_BY_KEYWORDS", addons);
      })
      .catch((err) => {
        messagePopUp("Oops...", "Something went wrong!", "error", 1500);
      });
  },
  FIND_ADDONS_BY_FOLDER: ({ commit }, { page, take, folderId, clientId }) => {
    apollo
      .query({
        query: FIND_ADDONS_BY_FOLDER_ID,
        variables: { page, take, folderId, clientId },
        fetchPolicy: "no-cache",
      })
      .then(({ data: { addons } }) => {
        commit("SET_ADDONS", addons);
      })
      .catch((err) => {
        console.error(err);
        messagePopUp("Oops...", "Something went wrong!", "error", 1500);
      });
  },
}
