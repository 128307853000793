import {
  FIND_FOLDERS_WITH_POSTS,
  FIND_LATEST_POSTS,
  FIND_POST,
  FIND_POSTS_BY_TYPE,
  FIND_POSTS_BY_FOLDER_ID
} from '../graphql/query'
import { apollo } from '../../../plugins/vue-apollo'
// import { UPDATE_FOLDER } from "../../../post/folders/graphql/mutations";

export default {
  FIND_LATEST_POSTS_BY_TYPE: (
    { commit, dispatch },
    { take, typeUser, clientId },
  ) => {
    apollo
      .query({
        query: FIND_LATEST_POSTS,
        variables: { take, typeUser, clientId },
        fetchPolicy: 'no-cache',
      })
      .then(({ data: { items } }) => {
        commit('SET_LATEST_POSTS', items)
      })
      .catch((err) => {
        console.log(err)
        dispatch('snackbar/errors', err, { root: true })
      })
  },

  LIST_POSTS_WITH_FOLDER: ({ commit }, { take, page, limitPost, clientId }) => {
    apollo
      .query({
        query: FIND_FOLDERS_WITH_POSTS,
        variables: { take, page, limitPost, clientId },
        fetchPolicy: 'no-cache',
      })
      .then(({ data: { folders } }) => {
        commit('SET_FOLDERS_WITH_POSTS', folders)
      })
      .catch((err) => {
        console.log(err)
      })
  },
  FIND_POST: ({ commit }, uuid) => {
    apollo
      .query({
        query: FIND_POST,
        variables: { uuid },
        fetchPolicy: 'no-cache',
      })
      .then(({ data: { post } }) => {
        commit('SET_POST', post)
      })
      .catch((err) => {
        console.log(err)
      })
  },
  FIND_POSTS_BY_USERS: (
    { commit, dispatch },
    { page, take, keywords, user, clientId }
  ) => {
    apollo
      .query({
        query: FIND_POSTS_BY_TYPE,
        variables: { page, take, names: keywords, user, clientId },
      })
      .then(({ data: { posts } }) => {
        switch (user) {
          case "user":
            commit("SET_POSTS_BY_USER", posts);
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        dispatch("snackbar/errors", err, { root: true });
      });
  },
  FIND_POSTS_BY_FOLDER: ({ commit }, { page, take, folderId, clientId }) => {
    apollo
      .query({
        query: FIND_POSTS_BY_FOLDER_ID,
        variables: { page, take, folderId, clientId },
        fetchPolicy: "no-cache",
      })
      .then(({ data: { posts } }) => {
        commit("SET_POSTS", posts);
      })
      .catch((err) => {
        console.error(err);
        messagePopUp("Oops...", "Something went wrong!", "error", 1500);
      });
  },
};
