import gql from 'graphql-tag'
import { UPLOAD } from './variables'

export const FIND_UPLOADS = gql(`
  query ($take:Int, $page:Int, $clientId:Int!,$type:String) {
    uploads(take: $take, page: $page, clientId: $clientId,type:$type) {
      ${UPLOAD}
      
    }
  }
`)
export const FIND_UPLOADS_BY_KEYWORDS = gql(`
  query UploadsByKeywords($take:Int, $page:Int, $keywords:[String!]!, $typeUser: String, $clientId: Int, $type: String){
    uploads: uploadsByKeywords(take: $take, page: $page, keywords: $keywords, typeUser: $typeUser, clientId: $clientId, type: $type) {
      ${UPLOAD}
    }
  }
`);
