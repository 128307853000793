<template>
  <v-app id="main">
    <v-main>
      <router-view></router-view>
      <no-auth-modal/> 
      <cp-isc-double-btn-modal
        v-model="isShowModalBtn"
        :title="titleModalBtn"
        :paragraph="paragraphModalBtn"
        btnMsg1="No"
        btnMsg2="Yes"
        :showLoader="isShowLoader"
        @send-result-btn1="redirectToLogin"
        @send-result-btn2="setTokenAndSession"
      />
    </v-main>
  </v-app>
</template>
<script>
import NoAuthModal from "./components/dialog/NoAuthModal.vue";
import { expTokenMixin } from "./mixins/expTokenMixin";
import { isTokenValid, CpIscDoubleBtnModal, DoubleBtnMixin } from "@design/styleguide";
export default {
    components: {
      NoAuthModal,
      CpIscDoubleBtnModal
    },
    mixins: [DoubleBtnMixin, expTokenMixin],
    data() {
    return {
      isAuthenticated: false,
    };
  },
  created() {
    // return true if the token exists 
    this.isAuthenticated = isTokenValid();
    if (!this.isAuthenticated) {
      const pathUrl = `${process.env.VUE_APP_URL_MONITOR}/login`;
      window.location.replace(pathUrl);
    }
  }
}
</script>
<style>
/* #main {
  margin-top: 60px;
}
.v-application--wrap {
  min-height: calc(100vh - 60px);
} */
@media (max-width: 1024px) {
  body{
    font-size: 14px !important;
  }
}
</style>
