export default {
  SET_UPLOADS: (state, uploads) => {
    state.uploads = uploads.map((upload) => {
      return {
        id: upload.id,
        src: upload.path,
        typeFile: upload.mimeType,
      };
    });
  },
  SET_UPLOADS_BY_KEYWORDS: (state, uploadsByKeywords) => {
    state.uploadsByKeywords = uploadsByKeywords;
  },
  /* ADD_UPLOAD: (state, upload) => {
    state.uploads.unshift(upload)
  }, */
  SET_LOAD_UPLOAD: (state, { percentCompleted, name }) => {
    const f = state.load.find(({ name }) => name == name)
    if (percentCompleted == 100) {
      if (f) {
        const index = state.load.indexOf(f)
        state.load.splice(index, 1)
      }
    } else {
      if (f) {
        f.val = percentCompleted
      } else {
        state.load.push({
          name,
          val: percentCompleted,
        })
      }
    }
  },
  SET_FILES: (state, files) => {
    state.files = files.map((el, i) => ({
      id: i + 1,
      progress: 0,
      error: false,
    }));
  },
  RESET_FILES_PROGRESS: (state, index) => {
    state.files.forEach((el, i) => {
      if (el.id === index + 1) {
        state.files.splice(i, 1);
      }
    });
  },
  SET_PROGRESS: (state, data) => {
    state.files.forEach((el, i) => {
      if (i === data.index) {
        el.progress = data.progress;
      }
    });
  },
  SET_ERROR: (state, data) => {
    state.files.forEach((el, i) => {
      if (el.id === (data.index + 1 )) {
        el.error = data.error,
        el.msgError = data.msgError
      }
    });
  },
  RESET_UPLOADS: (state) => {
    state.uploads = [];
  },
}
