const mutations = {
  SHOW_MODAL_NO_AUTH(state) {
    state.isNoAuth = true;
  },
  HIDE_MODAL_NO_AUTH(state) {
    state.isNoAuth = false;
  },
  SET_MSG_NO_AUTH(state, msgNoAuth) {
    state.msgNoAuth = msgNoAuth;
  },
};
export default mutations;
