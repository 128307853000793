import { apollo } from '../../../plugins/vue-apollo'

import { FIND_UPLOADS, FIND_UPLOADS_BY_KEYWORDS } from '../graphql/query'
import { CREATE_UPLOAD, DELETE_UPLOAD } from '../graphql/mutation'
import { messagePopUp } from "@design/styleguide";

export default {
  FIND_UPLOADS: ({ commit, dispatch }, { take, page, clientId }) => {
    apollo
      .query({
        query: FIND_UPLOADS,
        variables: { take, page, clientId },
        fetchPolicy: 'no-cache',
      })
      .then(({ data: { uploads } }) => {
        commit('SET_UPLOADS', uploads)
      })
      .catch((err) => {
        console.log(err)
        messagePopUp('Oops...', 'Something went wrong!', 'error', 1500)
      })
  },
  CREATE_UPLOAD: async ({ commit }, { upload, object }) => {
    //const clientId = object.clientId
    return await apollo
      .mutate({
        mutation: CREATE_UPLOAD,
        variables: {
          upload,
          object,
        },
        // update: (cache, { data: { upload } }) => {
        //   const data = cache.readQuery({
        //     query: FIND_UPLOADS,
        //     variables: { clientId },
        //   });
        //   data.uploads.unshift(upload);

        //   cache.writeQuery({
        //     query: FIND_UPLOADS,
        //     variables: { clientId },
        //     data,
        //   });
        //   commit("SET_UPLOADS", data.uploads);
        // },
      })
      .then(({ data: { upload } }) => {
        commit('SET_UPLOADS', [ upload ])
      })
      .catch((err) => {
        console.error(err)
        messagePopUp('Oops...', 'Something went wrong!', 'error', 1500)
      })
  },
  DELETE: async ({ commit }, { id, responseFunc }) => {
    return await apollo
      .mutate({
        mutation: DELETE_UPLOAD,
        variables: {
          id
        },
      })
      .catch((error) => {
        console.error("[Error] in Mutation DELETE (Upload[Publish]) ", error);
        const msgResp = error.message ; 
        const typeResp = "error";
        return responseFunc(msgResp, typeResp)
      });
  },
  FILES_PROGRESS: ({ commit }, { files }) => {
    commit("SET_FILES", files);
  },
  RESET_FILES: ({ commit }, { index }) => {
    commit("RESET_FILES_PROGRESS", index);
  },
  UPLOAD_PROGRESS: ({ commit }, { progress, index }) => {
    let data = {
      index,
      progress,
    };
    commit("SET_PROGRESS", data);
  },
  ERROR_UPLOAD: ({ commit }, { error, index, msgError  }) => {
    let data = {
      index,
      error,
      msgError
    };
    commit("SET_ERROR", data);
  },
  FIND_UPLOADS_BY_KEYWORDS: ({ commit, dispatch }, { take , page, keywords, typeUser, clientId, type }) => {
    apollo
      .query({
        query: FIND_UPLOADS_BY_KEYWORDS,
        variables: { 
          take, 
          page, 
          keywords, 
          typeUser, 
          clientId,
          type 
        },
      })
      .then(({ data: { uploads } }) => {
        commit("SET_UPLOADS_BY_KEYWORDS", uploads);
      })
      .catch((err) => {
        messagePopUp("Oops...", "Something went wrong!", "error", 1500);
      });
  },
  RESET_UPLOADS: ({ commit }) => {
    commit("RESET_UPLOADS");
  },
}
